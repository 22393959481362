function Button(props) {
  const { extra, children, onClickHandler } = props;
  return (
    <button 
      className={`rounded-xl bg-gradient-to-br from-brandSecondary to-brandLinear px-5 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-brandLinear/50 ${extra}`}
      onClick={onClickHandler}
      >
      {children}
    </button>
  );
}

export default Button;
