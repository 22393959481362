import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import routes from "routes.js";
import PanelBar from "views/panel/components/PanelBar";
import PanelLogout from "views/panel/components/PanelLogout";

export default function Panel(props) {
  const { ...rest } = props;
  
  const getRoutes = (routes) => {
	  const filteredRoutes = routes.filter(route => route.path !== "sign-in");
    return filteredRoutes.map((prop, key) => {
      if (prop.layout === "/panel") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
    <div className="relative flex h-full w-full">    
      <FixedPlugin />  
      <div className="flex h-full w-full bg-lightPrimary dark:!bg-intsamis-900 justify-center">
        <main
          className={`panel-main-wrapper`}
        >
          <div className="h-full">
            <PanelBar {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[79vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(routes)}

                <Route
                  path="/"
                  element={<Navigate to="/panel/services" replace />}
                />
              </Routes>
            </div>
            <PanelLogout/>
            <div className="p-3">
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
