import APIService from './apiservice';

class ChatService{
	
  async getChatMessages(query) {
	  return await APIService.doRequest(`api/v1/chat-messages`, 'GET', query, null);
  }

  async sendChatMessage(data) {
	  return await APIService.doRequest(`api/v1/send-chat-message`, 'POST', data, null);
  }

}

export default new ChatService();