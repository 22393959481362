import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { connect } from 'react-redux';

import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import ConsoleLayout from "layouts/console";
import PanelLayout from "layouts/panel";
import { useLocation } from "react-router-dom";

const App = ({auth}) => {

	const location = useLocation();
  const targetLocation = location.pathname;

  return (
      <Routes>
      	{auth.isLoggedIn && <Route path="/auth/sign-in" element={<Navigate to="/panel/services" />} />}      	
        {auth.isLoggedIn ? (
			<>
	          <Route path="/" element={<Navigate to={targetLocation !== '/' ? targetLocation : '/panel'} />} />
	          <Route path="console/*" element={<ConsoleLayout />} />
	          <Route path="admin/*" element={<AdminLayout />} />
	          <Route path="rtl/*" element={<RtlLayout />} />
            <Route path="panel/*" element={<PanelLayout />} />
        	</>
      ) : (
        <Route path="/*" element={<Navigate to="/auth/sign-in" replace />} />
      )}
        <Route path="auth/*" element={<AuthLayout />} />

      </Routes>
  );
};

const mapStateToProps = (state) => ({
	auth: {...state.auth},
  });
  
const mapDispatchToProps = (dispatch) => ({

});
  
  
export default connect(mapStateToProps, mapDispatchToProps)(App);
