import React from "react";
import { connect } from "react-redux";
import { logoutRequest } from "../../../redux/auth/authState";
import Button from "components/button";

const PanelLogout = ({user, logout, ...props}) => {

  const handleLogout = (e) => {
    console.log('test');
	  e.preventDefault();
	  logout();
  };

  return (
    <div className="flex h-full w-full bg-lightPrimary dark:!bg-intsamis-900 justify-end px-2">
      <Button onClickHandler={handleLogout}>Logout</Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: {...state.auth.user},
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelLogout);
