import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import chatSaga from './chat/saga';

function* rootSaga() {
  yield all([
    authSaga(),
    chatSaga(),
    // Other sagas can be included here
  ]);
}

export default rootSaga;
