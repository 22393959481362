import { combineReducers } from 'redux';
//import authReducer from './auth/reducer';
import chatReducer from './chat/chatState';
import authReducer from './auth/authState';

const rootReducer = combineReducers({
  auth: authReducer,
  chat: chatReducer
});

export default rootReducer;
