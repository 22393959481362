import APIService from './apiservice';
import cookie from 'js-cookie';
import { API_CLIENT_ID, API_CLIENT_SECRET } from '../constants';

// const client_id = process.env.API_CLIENT_ID || process.env.REACT_APP_API_CLIENT_ID;
// const client_secret = process.env.API_CLIENT_SECRET || process.env.REACT_APP_API_CLIENT_SECRET;
const grant_type = 'password';
const user_info = 'userInfo';
const scope = '*';


class AuthService{
	
  async login(username, password) {
	  const data = await APIService.doRequest(`oauth/token`, 'POST', {
              username,
              password,
              grant_type,
              scope,
              client_id: API_CLIENT_ID,
              client_secret: API_CLIENT_SECRET
          }, null);
        
      if (data?.access_token) {
		    APIService.setToken(data.access_token);		  
      }
      
      return data;
  }

  async logout() {
	  try{
		  await APIService.doRequest(`api/v1/logout`, 'POST');
		  APIService.deleteToken();
	  } catch (error){
		  
	  } finally {
		  
	  }
  }
  
  async getCurrentUser(){
	  let userInfo = this.getUserInfo();
	  if(userInfo === null && this.withAccessToken()){
		  userInfo = await APIService.doRequest(`api/v1/auth-user-details`, 'GET');
		  cookie.set(user_info, userInfo);
	  }
	  return userInfo;
  }
  
  withAccessToken() {
	  return APIService.getToken() ? true : false;
  }

  getAccessToken() {
	  return APIService.getToken();
  }

  getUserInfo(){
    const userInfo = cookie.get(user_info);
    if(userInfo){
      return JSON.parse(userInfo);
    }
    return null;
  }
}

export default new AuthService();