import { takeLatest, put } from 'redux-saga/effects';
import { loginSuccess, loginFail, 
    logoutSuccess, logoutFail, 
    getAuthUserSuccess, getAuthUserFail
 } from './authState';
import authservice from '../../services/authservice';

function* loginUser(action) {
  try {
    
    console.log(action);
    const { username, password } = action.payload;
    const authToken = yield authservice.login(username, password); 

    if(authToken){
      yield put(loginSuccess(authToken));

      try {
        const user = yield authservice.getCurrentUser(); 
        yield put(getAuthUserSuccess(user));

      } catch (error) {
          yield put(getAuthUserFail(error.message));
      }
    }

  } catch (error) {
    console.log(error.message);
    yield put(loginFail(error.message));
  }
}

function* logoutUser(action) {
    try {
      
      const authToken = yield authservice.logout(); 
      yield put(logoutSuccess(authToken));
  
    } catch (error) {
      yield put(logoutFail(error.message));
    }
  }

function* getAuthUser(action) {
    try {
        const user = yield authservice.getCurrentUser(); 
        yield put(getAuthUserSuccess(user));

    } catch (error) {
        yield put(getAuthUserFail(error.message));
    }
}

function* authSaga() {
  yield takeLatest('auth/loginRequest', loginUser);
  yield takeLatest('auth/logoutRequest', logoutUser);
  yield takeLatest('auth/getAuthUser', getAuthUser);
}


export default authSaga;
