import React , { useState, useEffect } from "react";
import { MdTimer } from "react-icons/md";
import CardItem from "components/card/CardItem";
import Button from "components/button";

const TimeCard = () => {

  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <CardItem 
      icon={<MdTimer className="card-icon"/>}
      title={"Time Card"}
    >

      <div class="text-center">
        <h2 class="mt-[15px] text-xl font-bold text-theme-700 dark:text-white">{currentDateTime.toLocaleDateString(undefined, options)}</h2>
        <h2 class="mb-[15px] text-4xl font-bold text-theme-700 dark:text-white">{currentDateTime.toLocaleTimeString()}</h2>
      </div>

      <div class="grid grid-cols-1 gap-5 gap-4">
        <Button> Time IN </Button>
        <Button>Time OUT</Button>
      </div>
      

      

    </CardItem>
  );
};

export default TimeCard;
