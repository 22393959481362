import { Link } from "react-router-dom";

const ServiceMenu = (props) => {
  const { icon, title, ...rest } = props;
  return (
    <Link className="!z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-intsamis-800 dark:text-white dark:shadow-none"
      {...rest}
    >
      <div className="flex flex-row items-center">
        <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
          <div className="rounded-full bg-lightPrimary p-3 dark:bg-intsamis-700">
            <span className="flex items-center text-theme-500 dark:text-white">
              {icon}
            </span>
          </div>
        </div>

        <div className="h-50 ml-4 flex w-auto flex-col justify-center">
          <h4 className="text-xl font-bold text-theme-700 dark:text-white">
            {title}
          </h4>
        </div>
      </div>
    </Link>
  );
};

export default ServiceMenu;
