import React from 'react';
import { connect } from "react-redux";
import ScrollToBottom from 'react-scroll-to-bottom';
import Message from './Message';

const Messages = ({messages,name}) => {
    return (
        <ScrollToBottom className="py-2 bg-white border-gray-900 max-h-[400px]">
        	{messages &&
        		messages.map((message,i) => <div key={i}><Message message={message} name={name}/></div>) 
        	}          
       </ScrollToBottom>
        
    )
}

const mapStateToProps = (state) => ({
  user: {...state.auth.user},
});

const mapDispatchToProps = (dispatch) => ({
  
});


export default connect(mapStateToProps, mapDispatchToProps)(Messages);
