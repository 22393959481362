import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    conversationList: {
      requesting: false,
      items: [],
      error: null
    },
    messageList: {
      requesting: false,
      items: [],
      error: null
    },
    messageDetails: {
      requesting: false,
      error: null,
      success: false,
      data: null
    },
  };

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    getChatMessages: (state) => {
        state.messageList.requesting = true;
        state.messageList.error = null;
    },
    getChatMessagesSuccess: (state, action) => {
        state.messageList.requesting = false;
        state.messageList.items = action.payload;
    },
    getChatMessagesFail: (state, action) => {        
        state.messageList.requesting = false;
        state.messageList.error = action.payload;
    },
    sendChatMessage: (state) => {
        state.messageDetails.requesting = true;
        state.messageDetails.error = null;
    },
    sendChatMessageSuccess: (state, action) => {
        state.messageDetails.requesting = false;
        state.messageDetails.data = action.payload;
        state.messageDetails.success = true;
    },
    sendChatMessageFail: (state, action) => {
        state.messageDetails.requesting = false;
        state.messageDetails.error = action.payload;
        state.messageDetails.success = false;
    },
    receiveNewMessage: (state, action) => {
      state.messageList.requesting = false;
      state.messageList.items = [...state.messageList.items, action.payload];
    },
  },
})

export const { 
    getChatMessages, getChatMessagesSuccess, getChatMessagesFail,
    sendChatMessage, sendChatMessageSuccess, sendChatMessageFail,
    receiveNewMessage
} = chatSlice.actions

export default chatSlice.reducer