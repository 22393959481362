import React, { useEffect } from "react";
import { connect } from "react-redux";
import Pusher from 'pusher-js';
import { PUSHER_APP_KEY, PUSHER_APP_CLUSTER } from '../../../constants';
import { getChatMessages, receiveNewMessage } from "../../../redux/chat/chatState";
import InputMessage from "./InputMessage";
import Messages from "./Messages";

const ChatSupport = ({user, messageList, getChatMessages, receiveNewMessage}) => {

  useEffect(() => {
    getChatMessages();

    const pusher = new Pusher(PUSHER_APP_KEY, {
      cluster: PUSHER_APP_CLUSTER
    });
    console.log((user.id + '').padStart(5, '0'));
    const channel = pusher.subscribe(`support-chat-${(user.id + '').padStart(5, '0')}`);
    channel.bind('support-chat', (data) => {
		  console.log('New message received:', data);
      receiveNewMessage({message: data.message, name:data.user.username});
    });

    return () => {
      	channel.unbind_all();
      	channel.unsubscribe();
    };
  }, [getChatMessages, receiveNewMessage]);

  return (
    <div className="mt-16 mb-16 mt-3 grid grid-cols-1 gap-5">
      
      <Messages messages={messageList.items}
		  name="super4admin"/>
      
      <InputMessage />
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: {...state.auth.user},
  messageList: { ...state.chat.messageList},
});

const mapDispatchToProps = (dispatch) => ({
  getChatMessages: () => dispatch(getChatMessages()),
  receiveNewMessage: (message) => dispatch(receiveNewMessage(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatSupport);