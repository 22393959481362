import React from "react";

// Admin Imports
import Dashboard from "views/console/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
} from "react-icons/md";
import Services from "views/panel/Services";
import ChatSupport from "views/panel/chat-support";

const routes = [
  {
    name: "Dashboard",
    layout: "/console",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: <Dashboard />,
  },
  {
    name: "NFT Marketplace",
    layout: "/admin",
    path: "nft-marketplace",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  {
    name: "Data Tables",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "data-tables",
    component: <DataTables />,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Panel Services",
    layout: "/panel",
    path: "services",
    icon: <MdLock className="h-6 w-6" />,
    component: <Services />,
  },
  {
    name: "Chat Support",
    layout: "/panel",
    path: "chat-support",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <ChatSupport />,
    secondary: true,
  },
];
export default routes;
