import React from "react";
import Checkbox from "components/checkbox";
import { MdCheckCircle } from "react-icons/md";
import CardItem from "components/card/CardItem";

const TaskCard = () => {
  return (
    <CardItem
      icon={<MdCheckCircle className="h-6 w-6 text-theme-500 dark:text-theme-400" />}
      title={"Tasks"}
    >
      <div className="h-full w-full">
        <div className="mt-5 flex items-center justify-between p-2">
          <div className="flex items-center justify-center gap-2">
            <Checkbox />
            <p className="text-base font-bold text-theme-700 dark:text-white">
              Project 1 - Task Title
            </p>
          </div>
          
        </div>

        <div className="mt-2 flex items-center justify-between p-2">
          <div className="flex items-center justify-center gap-2">
            <Checkbox />
            <p className="text-base font-bold text-theme-700 dark:text-white">
            Project 1 - Task Title
            </p>
          </div>
          
        </div>

        <div className="mt-2 flex items-center justify-between p-2">
          <div className="flex items-center justify-center gap-2">
            <Checkbox />
            <p className="text-base font-bold text-theme-700 dark:text-white">
            Project 2 - Task Title
            </p>
          </div>
          
        </div>

        <div className="mt-2 flex items-center justify-between p-2">
          <div className="flex items-center justify-center gap-2">
            <Checkbox />
            <p className="text-base font-bold text-theme-700 dark:text-white">
            Project 3 - Task Title
            </p>
          </div>
          
        </div>

        <div className="mt-2 flex items-center justify-between p-2">
          <div className="flex items-center justify-center gap-2">
            <Checkbox />
            <p className="text-base font-bold text-theme-700 dark:text-white">
            Project 3 - Task Title
            </p>
          </div>
          
        </div>
      </div>
    </CardItem>
  );
};

export default TaskCard;
