import React, { useState, useEffect  }  from "react";
import InputField from "components/fields/InputField";
import Button from "components/button";
import CardItem from "components/card/CardItem";
import { ReactComponent as Logo } from 'assets/svg/intsamis_logo.svg';
import { connect } from "react-redux";
import { loginRequest } from "../../redux/auth/authState";
import { useLocation } from "react-router-dom";

const SignIn = ({ login }) => {
  const [inputs, setInputs] = useState({});
  const [fromKiosk, setFromKiosk] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type');
  const username = searchParams.get('username');

  useEffect(() => {
    if (type === 'kiosk' && username) {
      setFromKiosk(true);
      setInputs(values => ({ ...values, username: username }));
    }
  }, [type, username]); 

  const handleChange = (event) => {
    const name = event.target.id;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    try {   
      login({username: inputs.username, password:inputs.password});
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-center">
      
      <CardItem 
      extra="w-[400px] mt-8 border-1"
      icon={<Logo/>}
      htmlTitle={<h4 className="ml-4 card-title text-3xl dark:text-theme-200">Sign In</h4>}>
      
      <form onSubmit={handleSubmit}>
        <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">

          {fromKiosk ? 
            <div class="mb-3">
              <label for="username" class="text-sm text-theme-700 dark:text-white ml-1.5 font-medium">Username*</label>
              <input 
                className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                type="text" 
                id="username" 
                placeholder="username" 
                value={username}
                disabled={true}
              />
            </div>
          : <InputField
            variant="auth"
            extra="mb-3"
            label="Username*"
            placeholder="username"
            id="username"
            type="text"
            value={inputs.username}
            onChange={handleChange}
          />
          }

          <InputField
            variant="auth"
            extra="mb-3"
            label="Password*"
            placeholder="Min. 8 characters"
            id="password"
            type="password"
            value={inputs.password}
            onChange={handleChange}
          />

          <div className="mb-4 flex items-center justify-end px-2">
            {/* <div className="flex items-center">
            <Checkbox id="remember-me" checked={inputs.rememberMe} onChange={handleChange} />
              <p className="ml-2 text-sm font-medium text-theme-700 dark:text-white">
                Keep me logged In
              </p>
            </div> */}
            <a
              className="text-sm font-medium text-theme-500 hover:text-theme-600 dark:text-white"
              href=" "
            >
              Forgot Password?
            </a>
          </div>
          <Button type="submit" extra="w-full">
            Sign In
          </Button>
          {/* <div className="mt-4">
            <span className=" text-sm font-medium text-theme-700 dark:text-gray-600">
              Not registered yet?
            </span>
            <a
              href=" "
              className="ml-1 text-sm font-medium text-theme-500 hover:text-theme-600 dark:text-white"
            >
              Create an account
            </a>
          </div> */}
        </div>
      </form>
      </CardItem>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  error: state.auth.error,
});

const mapDispatchToProps = (dispatch) => ({
  login: (username, password) => dispatch(loginRequest(username, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);