import React from "react";
import Card from "components/card";

const CardItem = (props) => {
  const { extra, icon, title, htmlTitle, children, cardMenu } = props;
  return (
    <Card extra={`pb-7 p-[20px] ${extra}`}>
      {/* header */}
      <div className="relative flex flex-row justify-between">
        <div className="flex items-center">
          <div className="flex h-9 w-9 items-center justify-center rounded-full bg-lightPrimary dark:bg-intsamis-800 dark:bg-white/5">
            {icon}
          </div>
          {htmlTitle ? htmlTitle 
            : <h4 className="ml-4 card-title">
              {title}
            </h4>
          }          
        </div>
        {cardMenu}
      </div>
      {/* content */}
      <div className="h-full w-full">
        {children}
      </div>
    </Card>
  );
};

export default CardItem;
