import React from "react";
import { connect } from "react-redux";
import { MdChat, MdDashboard } from "react-icons/md";
import ServiceMenu from "./components/ServiceMenu";

const Services = () => {

  return (
    <div className="mt-16 mb-16 mt-3 grid grid-cols-1 gap-5">
      <ServiceMenu
          icon={<MdDashboard className="h-7 w-7" />}
          title={"Service 1"}
          to="/admin/profile"
      />
      <ServiceMenu
          icon={<MdDashboard className="h-7 w-7" />}
          title={"Service 2"}
          to="/panel/service-2"
      />
      <ServiceMenu
          icon={<MdDashboard className="h-7 w-7" />}
          title={"Service 3"}
          to="/panel/service-3"
      />
      <ServiceMenu
          icon={<MdChat className="h-7 w-7" />}
          title={"Chat Support"}
          to="/panel/chat-support"
      />      
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: {...state.auth.user},
});

const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(Services);