import React from 'react';
import { connect } from "react-redux";

const Message = ({message: {user,text},name}) => {
    const trimmedName = name.trim().toLowerCase();
    return (
        <div className={`grid mb-5 mx-3 p-2 rounded ${user === trimmedName ? 'justify-items-end bg-blue-100' : 'justify-items-start'}`}>
            <p className="font-bold">{user === trimmedName ? trimmedName : user}</p>
            <div className="max-w-md">
                <div style={{whiteSpace: 'pre-line'}}>{text}</div>   
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
  user: {...state.auth.user},
});

const mapDispatchToProps = (dispatch) => ({
  
});


export default connect(mapStateToProps, mapDispatchToProps)(Message);
