import { takeLatest, put } from 'redux-saga/effects';
import chatservice from 'services/chatservice';
import {
  getChatMessagesSuccess, getChatMessagesFail,
  sendChatMessageSuccess, sendChatMessageFail
} from './chatState';


function* getChatMessages(data) {
  try {
    console.log('test');
    const resp = yield chatservice.getChatMessages(data.payload);
    yield put(getChatMessagesSuccess(resp.data));
  } catch (e) {
    const error = yield Promise.resolve(e);
    yield put(getChatMessagesFail(error));
  }
}

function* sendChatMessage(data) {
  try {
    const resp = yield chatservice.sendChatMessage(data.payload);
    yield put(sendChatMessageSuccess(resp.data));
  } catch (e) {
    const error = yield Promise.resolve(e);
    yield put(sendChatMessageFail(error));
  }
}

function* chatSaga() {
  yield takeLatest('chat/getChatMessages', getChatMessages);
  yield takeLatest('chat/sendChatMessage', sendChatMessage);
}

export default chatSaga;