import React from "react";
import avatar from "assets/img/avatars/avatar4.png";
import { connect } from "react-redux";

const PanelBar = ({user, ...props}) => {

  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-center rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
      <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-end gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-intsamis-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">        
        <div className="flex gap-3">
          <div className="flex items-center gap-2">
            <p className="text-sm font-bold text-theme-700 dark:text-white">
              Hello, {user?.username}
            </p>{" "}
          </div>  
          <img
            className="h-10 w-10 rounded-full"
            src={avatar}
            alt="Juan dela Cruz"
          />                      
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  user: {...state.auth.user},
});

const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelBar);
