import React, { createContext, useContext, useState} from "react";
import authservice from 'services/authservice';

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [state, setState] = useState({
	  	accessToken: authservice.getAccessToken(),
	  	oauthToken: null,	  	
    	user: null,
    	isLoggedIn: authservice.withAccessToken(),
    	isLoginPending: false,
    	loginError: null,
  });
  
  const login = async (email, password) => {
    setState({ ...state, isLoginPending: true });

    try {
      const result = await authservice.login(email, password);

      if (result) {
		  updateOauthToken(result);
		  updateAccessToken(result.access_token);	  
      } else {
        setState({ ...state, loginError: 'Authentication failed' });
      }
    } catch (error) {
      setState({ ...state, loginError: 'An error occurred during login'});
    } finally {
      setState({ ...state, isLoginPending: false });
    }
  };

  const logout = async () => {
	try {
      const result = await authservice.logout();
		updateUser(null);
		updateAccessToken(null);
      if (result) {
		  console.log(result);
		  
    	return result;
      } else {
        //setState({ ...state, loginError: 'Authentication failed' });
      }
    } catch (error) {
		console.log(error);
      //setState({ ...state, loginError: 'An error occurred during login'});
    } finally {
      //setState({ ...state, isLoginPending: false });
    }
  };
  
  const getAuthUser = async () => {
	  const result = await authservice.getCurrentUser();
	  updateUser(result);
  }

  const useAuth = () => {
	  const context = useContext(AuthContext);
	  if (!context) {
		  throw new Error("useAuth must be used within an AuthContextProvider");
	  }
	  return context;
  };
  
  const updateAccessToken = (token) => {
	  const validToken = token ? true : false;
	  setState(prevState => ({...prevState, accessToken: token, isLoggedIn: validToken,}));
  }
  
  const updateUser = (user) => {
	  console.log(user);
	  setState(prevState => ({...prevState, user: user}));
  }
  
  const updateOauthToken = (oauth) => {
	  setState(prevState => ({...prevState, oauthToken: oauth}));
  }

  return (
    <AuthContext.Provider value={{ state, login, logout, useAuth, getAuthUser, updateUser, updateAccessToken, updateOauthToken }}>
      {children}
    </AuthContext.Provider>
  );
};
