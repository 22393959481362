import { createSlice } from '@reduxjs/toolkit'
import authservice from '../../services/authservice';

const initialState = {
    user: authservice.getUserInfo(),
    accessToken: authservice.getAccessToken(),
    isLoggedIn: authservice.withAccessToken(),
    loading: false,
    error: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest: (state) => {
        state.loading = true;
        state.error = null;
    },
    loginSuccess: (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.accessToken = action.payload;
        state.error = null;
    },
    loginFail: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    logoutRequest: (state) => {
        state.loading = true;
        state.error = null;
    },
    logoutSuccess: (state) => {
        state.loading = false;
        state.isLoggedIn = false;
        state.user = null;
        state.accessToken = null;
        state.error = null;
    },
    logoutFail: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    getAuthUser: (state) => {
        state.loading = true;
        state.error = null;
    },
    getAuthUserSuccess: (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
    },
    getAuthUserFail: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
  },
})

export const { 
    loginRequest, loginSuccess, loginFail,
    logoutRequest, logoutSuccess, logoutFail,
    getAuthUser, getAuthUserSuccess, getAuthUserFail
} = authSlice.actions

export default authSlice.reducer