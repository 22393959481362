import React from 'react';
import { connect } from "react-redux";
import {  SendOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';

const InputMessage = ({message,setMessage,sendMessage}) => {
    return (
        <form className="form">
    		<Space.Compact style={{ width: '100%' }} size="large">
		      	<Input style={{ width: '90%' }}
	                value={message} 
	                placeholder="Enter Message" 
	                onChange={(event) => setMessage(event.target.value)} 
	                onPressEnter={(event) => sendMessage(event)}
	            />
	            <Button style={{ width: '10%' }}
	            	className="text-gray-900 bg-white border-gray-500 shadow-none"
	                type="primary" 
	                icon = {<SendOutlined />}
	                onClick={(event) => sendMessage(event)}
	            >
	            </Button>
		    </Space.Compact>       	
        </form>
        
    )
}

const mapStateToProps = (state) => ({
  user: {...state.auth.user},
});

const mapDispatchToProps = (dispatch) => ({
  
});


export default connect(mapStateToProps, mapDispatchToProps)(InputMessage);
